import {m} from 'framer-motion';
import Article from '@/components/article';
import Layout from '@/components/layout';
import {fadeX} from '@/helpers/animation/fade';
import {heading} from '@/helpers/animation/heading';
import icons from '@/UI/icons';
import IconsEnum from '@/UI/icons/types/iconsEnum';

const Page: React.FC = () => (
    <Layout>
        <Article>
            <m.h2
                initial="initial"
                animate="enter"
                exit="exit"
                variants={heading}
            >
                <i className="icon">{icons[IconsEnum.chartBubble]()}</i>
                O mně
            </m.h2>
            <m.div
                initial="initial"
                animate="enter"
                exit="exit"
                variants={fadeX}
            >
                <p>
                    Jmenuji se&nbsp;Markéta&nbsp;Hříbalová a&nbsp;pocházím z&nbsp;Prahy.
                </p>
                <p>
                    Již v&nbsp;rámci studia vysoké školy jsem se&nbsp;zajímala o&nbsp;zdravou výživu
                    a&nbsp;zdravý životní styl, až se&nbsp;tato záliba stala nejen mým koníčkem, ale i&nbsp;zaměstnáním.
                </p>
                <p>
                    Stala jsem se&nbsp;zkušenou instruktorkou pilates, jemné jógy, office jógy a&nbsp;jógy pro těhotné.
                </p>
            </m.div>
        </Article>
    </Layout>
);

export default Page;
